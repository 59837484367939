//
// Carousel
//

.carousel-indicators {
  position: relative;
  left: 0; 
  display: block;
  width: 100%;
  padding: 0;
  margin: 20px 0 0 0;
  text-align: left;

  > li {
    display: inline-block;
    padding: 0;
    margin: 0 1px;
    background-color: fade(white, 30%);
    border-width: 0;

    &.active {
      display: inline-block;
      margin: 0 1px;
      height: 10px; width: 10px;
      background-color: white;
    }
  }
}