//
// Elements animation
//

// Hide animated elements

section [data-animate-in] {
  opacity: 0;
}


// Animation delays

@animation-delay-step: 200ms;
@animation-delay-small: 100ms;

.delay_1 {
  -webkit-animation-delay: (@animation-delay-step);
          animation-delay: (@animation-delay-step);  
}
.delay_2 {
  -webkit-animation-delay: (@animation-delay-step*2);
          animation-delay: (@animation-delay-step*2);
}
.delay_2_1 {
  -webkit-animation-delay: (@animation-delay-step*2 + @animation-delay-small);
          animation-delay: (@animation-delay-step*2 + @animation-delay-small);  
}
.delay_2_2 {
  -webkit-animation-delay: (@animation-delay-step*2 + @animation-delay-small*2);
          animation-delay: (@animation-delay-step*2 + @animation-delay-small*2);  
}
.delay_2_3 {
  -webkit-animation-delay: (@animation-delay-step*2 + @animation-delay-small*3);
          animation-delay: (@animation-delay-step*2 + @animation-delay-small*3);  
}
.delay_3 {
  -webkit-animation-delay: (@animation-delay-step*3);
          animation-delay: (@animation-delay-step*3);
}
.delay_4 {
  -webkit-animation-delay: (@animation-delay-step*4);
          animation-delay: (@animation-delay-step*4);
}
.delay_5 {
  -webkit-animation-delay: (@animation-delay-step*5);
          animation-delay: (@animation-delay-step*5);
}
.delay_6 {
  -webkit-animation-delay: (@animation-delay-step*6);
          animation-delay: (@animation-delay-step*6);
}
.delay_7 {
  -webkit-animation-delay: (@animation-delay-step*7);
          animation-delay: (@animation-delay-step*7);
}
.delay_8 {
  -webkit-animation-delay: (@animation-delay-step*8);
          animation-delay: (@animation-delay-step*8);
}
.delay_9 {
  -webkit-animation-delay: (@animation-delay-step*9);
          animation-delay: (@animation-delay-step*9);
}
.delay_10 {
  -webkit-animation-delay: (@animation-delay-step*10);
          animation-delay: (@animation-delay-step*10);
}


// Custom animation

.animateUpDown {
  -webkit-animation-name: animateUpDown;
          animation-name: animateUpDown;
  -webkit-animation-duration: 900ms;
          animation-duration: 900ms;
}
@-webkit-keyframes animateUpDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  40% {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@keyframes animateUpDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  40% {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

.animateUp {
  -webkit-animation-name: animateUp;
          animation-name: animateUp;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-timing-function: @timing-fn;
          animation-timing-function: @timing-fn;
}
@-webkit-keyframes animateUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@keyframes animateUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}