// 
// Typography
//

@text-color: white;
@link-color: white;
@link-hover-color: white;
@font-size-base: 16px;
@font-family-base: 'Open Sans', sans-serif;
@headings-font-family: 'Questrial', sans-serif;

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1.3;
}

p {
  margin-bottom: (@line-height-computed*1.5);
  font-weight: 300;
  line-height: 1.7;
  color: fade(@text-color,80%);

  + p {
    margin-top: -(@line-height-computed*0.5);
  }
}

.list-ticked {
  list-style: none;
  margin: 20px 0;
  padding-left: 0;

  > li {
    font-weight: 300;
    color: fade(@text-color,80%);
    margin-bottom: 10px;

    > .fa {
      display: inline-block;
      width: 30px; height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: fade(black,20%);
      color: fade(white, 100%);
      font-size: 12px;
      margin-right: 15px;
    }
  }
}